/* You can add global styles to this file, and also import other style files */
@import "./assets/fonts/roboto/roboto.scss";
@import "./assets/icons/icons.scss";

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

:root {
    --header-height: 60px;
    --filter-height: 80px;
    --page-header-height: 80px;
}

.page-header {
    height: var(--page-header-height);
}

h1 {
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

/* **** Sheets for table listing pages **** */
.base-container {
    width: auto;
    height: 100vh;
    z-index: -1;
  }

  .h1-settings {
    line-height: 24px;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  .list-item-extend {
    display: block;
  }

  .filter-container {
    display: flex;
    width: 100%;
    height: var(--filter-height);
    align-self: center;
  }

  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Adjust the size as needed */
  }

/* Structure */
table {
  width: 100%;
}

.mat-form-field {
  font-size: 14px;
  width: 100%;
}

th.mat-sort-header-sorted {
  color: black;
}

.scroll-table-container {
  position: relative;
  height: calc(100vh - var(--header-height) - var(--filter-height) - var(--page-header-height));
  overflow: auto;
}

tr.mat-row-hover:hover {
  cursor: pointer;
  background-color: #CFEAF7;
}